import React, { useEffect, useState } from 'react';

const GoogleAd = ({ adUnitPath, size, divId, popup }) => {
  const [isAdEmpty, setIsAdEmpty] = useState(null); // Initially assume the ad is empty
  const [isPopupVisible, setPopupVisible] = useState(popup); // Manage popup visibility
  const [showPopup, setShowPopup] = useState(true); // New state to manage if the popup is rendered
  useEffect(() => {
    const loadGoogleAds = () => {
      window.googletag = window.googletag || { cmd: [] };

      window.googletag.cmd.push(() => {
        const slot = googletag
          .defineSlot(adUnitPath, size, divId)
          .addService(googletag.pubads());

        // Add an event listener to detect if the ad is empty
        googletag.pubads().addEventListener('slotRenderEnded', event => {
          if (event.slot === slot) {
            setIsAdEmpty(event.isEmpty); // Update state based on whether the ad is empty
          }
        });

        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });

      googletag.cmd.push(() => {
        googletag.display(divId);
      });
    };

    loadGoogleAds();
  }, [adUnitPath, size, divId]);

  const handleClosePopup = () => {
    setShowPopup(false); // Remove the popup div by setting the state to false
  };

  const handlePopupClick = (e) => {
    // Close the popup only if clicking outside the content area
    if (e.target === e.currentTarget) {
      handleClosePopup();
    }
  };

  if (!showPopup) {
    return null; // Return null to remove the entire popup from the DOM
  }

  return (
    <>
      {isPopupVisible && !isAdEmpty ? (
        <div className="pop-up pos-fix fullheight fullwidth" onClick={handlePopupClick}>
          <div className="pop-up-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={handleClosePopup}>×</button>
            <div id={divId} style={{ minWidth: size[0], minHeight: size[1] }}></div>
          </div>
        </div>
      ) : !isAdEmpty ? (
        <div id={divId} style={{ minWidth: size[0], minHeight: size[1] }}></div>
      ) : (
        null
      )}
    </>
  );
};

export default GoogleAd;
