import { useState, useEffect, useRef, useCallback, Fragment } from 'react'
import basepath from '#components/basepath'
import useEmblaCarousel from 'embla-carousel-react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { loaderProp } from '#root/utils/image-utils'

export default function MediaCarousel() {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, dragFree: true, align: 'start' })
  const emblaContainerRef = useRef(null)
  const { t } = useTranslation(['common'])

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  //Media list
  let items = [
    {
      name: 'Fischer Medical Ventures Ltd and Health Digital Technologies Partner to Expand DoctorOnCall Globally',
      image: 'media-240801-001.jpg',
      category: 'APN News',
      url: 'https://media.doctoroncall.com/apn-news/fischer-medical-ventures-ltd-and-health-digital-technologies-partner-to-expand-doctoroncall-globally/'
    },
    {
      name: 'Fischer Medical Ventures Ltd and Malaysia’s Health Digital Technologies Firm Up Investment and Collaboration to Launch Their DoctorOnCall Platform Worldwide',
      image: 'media-240801-003.jpg',
      category: 'Malaysian Business',
      url: 'https://media.doctoroncall.com/malaysian-business/fischer-medical-ventures-ltd-and-malaysias-health-digital-technologies-firm-up-investment-and-collaboration-to-launch-their-doctoroncall-platform-worldwide/'
    },
    {
      name: 'Fischer Medical Ventures and Health Digital Technologies Partner to Launch DoctorOnCall Globally',
      image: 'media-240801-002.jpg',
      category: 'Bio Voice News',
      url: 'https://media.doctoroncall.com/biovoicenews/fischer-medical-ventures-and-health-digital-technologies-partner-to-launch-doctoroncall-globally/'
    },
    {
      name: '#TECH: DoctorOnCall and Bumitra ink MoU to facilitate vaccination for Muslim pilgrims',
      image: 'media1.jpg',
      category: 'New Straits Times',
      url: 'https://media.doctoroncall.com/new-straits-times/tech-doctoroncall-and-bumitra-ink-mou-to-facilitate-vaccination-for-muslim-pilgrims/'
    },
    {
      name: 'The National Cancer Society of Malaysia (NCSM), MSD and DoctorOnCall Collaborate In Conjunction with World Cancer Day 2023 to Offer Affordable Health Screenings to Members of the Public',
      image: 'media2.jpg',
      category: 'PR Newswire',
      url: 'https://media.doctoroncall.com/pr-newswire/the-national-cancer-society-of-malaysia-ncsm-msd-and-doctoroncall-collaborate-in-conjunction-with-world-cancer-day-2023-to-offer-affordable-health-screenings-to-members-of-the-public/'
    },
    {
      name: '#TECH: Bayer Launches Telehealth Platform For Endometriosism',
      image: 'media3.jpg',
      category: 'New Straits Times',
      url: 'https://media.doctoroncall.com/new-straits-times/tech-bayer-launches-telehealth-platform-for-endometriosis/'
    },
    {
      name: 'DoctorOnCall Inks MoU With PERDIM To Bridge Healthcare Providers And Patients Through Digital Platform',
      image: 'media4.jpg',
      category: 'Business Today',
      url: 'https://media.doctoroncall.com/business-today/doctoroncall-inks-mou-with-perdim-to-bridge-healthcare-providers-and-patients-through-digital-platform/'
    },
    {
      name: 'DoctorOnCall Inks MOU With Perdim',
      image: 'media5.jpg',
      category: 'Code Blue',
      url: 'https://media.doctoroncall.com/code-blue/doctoroncall-inks-mou-with-perdim/'
    },
    {
      name: 'MENA QIC InsurTech Hackathon — Leveraging Personal Data for Health, Mobility and Insurance Apps',
      image: 'media6.jpg',
      category: 'Medium',
      url: 'https://media.doctoroncall.com/medium/mena-qic-insurtech-hackathon-leveraging-personal-data-for-health-mobility-and-insurance-apps/'
    },
    {
      name: '#TECH: DoctorOnCall and Bumitra ink MoU to facilitate vaccination for Muslim pilgrims',
      image: 'media1.jpg',
      category: 'New Straits Times',
      url: 'https://media.doctoroncall.com/new-straits-times/tech-doctoroncall-and-bumitra-ink-mou-to-facilitate-vaccination-for-muslim-pilgrims/'
    },
    {
      name: 'The National Cancer Society of Malaysia (NCSM), MSD and DoctorOnCall Collaborate In Conjunction with World Cancer Day 2023 to Offer Affordable Health Screenings to Members of the Public',
      image: 'media2.jpg',
      category: 'PR Newswire',
      url: 'https://media.doctoroncall.com/pr-newswire/the-national-cancer-society-of-malaysia-ncsm-msd-and-doctoroncall-collaborate-in-conjunction-with-world-cancer-day-2023-to-offer-affordable-health-screenings-to-members-of-the-public/'
    },
    {
      name: '#TECH: Bayer Launches Telehealth Platform For Endometriosism',
      image: 'media3.jpg',
      category: 'New Straits Times',
      url: 'https://media.doctoroncall.com/new-straits-times/tech-bayer-launches-telehealth-platform-for-endometriosis/'
    },
    {
      name: 'DoctorOnCall Inks MoU With PERDIM To Bridge Healthcare Providers And Patients Through Digital Platform',
      image: 'media4.jpg',
      category: 'Business Today',
      url: 'https://media.doctoroncall.com/business-today/doctoroncall-inks-mou-with-perdim-to-bridge-healthcare-providers-and-patients-through-digital-platform/'
    },
    {
      name: 'DoctorOnCall Inks MOU With Perdim',
      image: 'media5.jpg',
      category: 'Code Blue',
      url: 'https://media.doctoroncall.com/code-blue/doctoroncall-inks-mou-with-perdim/'
    },
    {
      name: 'MENA QIC InsurTech Hackathon — Leveraging Personal Data for Health, Mobility and Insurance Apps',
      image: 'media6.jpg',
      category: 'Medium',
      url: 'https://media.doctoroncall.com/medium/mena-qic-insurtech-hackathon-leveraging-personal-data-for-health-mobility-and-insurance-apps/'
    }
  ]
  return (
    <div className="slider-container pos-rel">
      <div className="pl-1 pr-1 pb-1 wrap-1">
        <ul className="controls flex-box fullwidth ai-b ls-n">
          <li
            onClick={scrollPrev}
            className="prev"
            aria-controls="s2"
            tabIndex={-1}
            data-controls="prev"
          >
            <i>
              <img src="https://www.doctoroncall.com/images/prev.svg" alt="prev" />
            </i>
          </li>
          <li
            onClick={scrollNext}
            className="next"
            aria-controls="s2"
            tabIndex={-1}
            data-controls="next"
          >
            <i>
              <img src="https://www.doctoroncall.com/images/next.svg" alt="next" />
            </i>
          </li>
          <li className="headline">
            <h2>{t('makingHeadlines')}</h2>
            <span>{t('makingHeadlinesDesc')}</span>
          </li>
          <li className="more">
            <a className="button-more" href="https://media.doctoroncall.com" target="_blank">
              <div className="dots">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div className="embla" ref={emblaRef}>
        <div className="embla__container" ref={emblaContainerRef}>
          {items?.map((x, i) => (
            <Fragment key={i}>
              <div className="slider-item tns-item embla__slide media-item">
                <a className="card" href={x?.url}>
                  <div className="img pos-rel" style={{ backgroundColor: 'unset' }}>
                    <div className="category pos-abs">
                      <span>{x?.category}</span>
                    </div>
                    <Image
                      placeholder="blur"
                      blurDataURL={basepath('/images/c01.jpg')}
                      src={`/images/${x?.image}`}
                      alt={x?.name}
                      width={200}
                      height={200}
                      loader={loaderProp}
                      className="mediaImg"
                    />
                  </div>

                  <div className="desc" style={{ padding: 0 }}>
                    <div
                      className="afd details flex-box fullwidth fd-c gap-05"
                      style={{ textAlign: 'left' }}
                    >
                      <div>
                        <h2>{x?.name}</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}
