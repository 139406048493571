import React, { useEffect, useRef, useState } from 'react'
import basepath from '#components/basepath'
import Api from '#api/Market'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Image from 'next/image'
import { anonymousAuth } from '#root/src/utilities/authUtils'
import { localeMapper } from '#root/utils/url-utils'
import { loaderProp } from '#root/utils/image-utils'
import ProductSlider from '#components/ProductSlider'
import RatingReview from '#components/RatingReview'
import MediaCarousel from '#components/MediaCarousel'
import GoogleAd from '#components/GoogleAd'
import { useSignUpOverlay } from '../global-storage/overlays'
import { schemaGeneratorMain } from '#root/utils/schema-utils'

export default function index({
  newProduct,
  locale,
  pharmacy,
  personalizedProducts,
  newPackageProduct,
  isMobile,
  userToken,
  translatedCategories,
  openSignUpOverlay,
  // productsCarousels,
  structuredData
}) {
  //global states
  const { setState: setSignUpOverlayState } = useSignUpOverlay(({ setState }) => ({ setState }))
  const { t } = useTranslation('index')
  const indexRef = useRef(0)

  const toggleBackground = () => {
    indexRef.current += 1
    return indexRef.current % 2 !== 0 ? 'bg-blue-2' : ''
  }
  //open signup overlay
  useEffect(() => {
    if (openSignUpOverlay) {
      setSignUpOverlayState('active')
    }
  }, [openSignUpOverlay])

  const PartnerLogos = () => {
    return (
      <div className="logos flex-box fw jc-c gap-1 centralized ai-c logosWidth">
        <div style={{ flexBasis: '4em' }}  >
          <Image  src="/images/partner-01.png" alt="Partner 01" width={138} height={150} />

        </div>
        <div>
        <Image src="/images/partner-02.png" alt="Partner 02" width={220} height={150} />

        </div>
        <div style={{ flexBasis: '4.2em' }}>
        <Image  src="/images/partner-03.png" alt="Partner 03" width={149} height={150} />
      </div>

      <div style={{ flexBasis: '3em' }}>
        <Image  src="/images/partner-04.png" alt="Partner 04" width={109} height={150} />
      </div>

      <div>
        <Image src="/images/partner-05.png" alt="Partner 05" width={232} height={150} />
      </div>

      <div style={{ flexBasis: '6.7em' }} >
        <Image src="/images/partner-06.png" alt="Partner 06" width={295} height={150} />
      </div>

      <div  style={{ flexBasis: '7em' }}> 
        <Image src="/images/partner-07.png" alt="Partner 07" width={311} height={150} />
      </div>

      <div style={{ flexBasis: '2.8em' }}>
        <Image className='potrait' src="/images/partner-08.png" alt="Partner 08" width={107} height={150} />
      </div>

      <div style={{ flexBasis: '1.5em' }}>
        <Image  className='potrait' src="/images/partner-09.png" alt="Partner 09" width={69} height={150} />
      </div>

      <div style={{ flexBasis: '4em' }}>
        <Image src="/images/partner-10.png" alt="Partner 10" width={173} height={150} />
      </div>

      <div>
        <Image src="/images/partner-11.png" alt="Partner 11" width={280} height={150} />
      </div>

      <div>
        <Image src="/images/partner-12.png" alt="Partner 12" width={309} height={150} />
      </div>

      <div>
        <Image src="/images/partner-13.png" alt="Partner 13" width={253} height={150} />
      </div>

      <div>
        <Image src="/images/partner-14.png" alt="Partner 14" width={228} height={130} />
      </div>

      <div>
        <Image src="/images/partner-15.png" alt="Partner 15" width={274} height={150} />
      </div>

      <div style={{ flexBasis: '3em' }} >
        <Image src="/images/partner-16.png" alt="Partner 16" width={150} height={150} />
      </div>

      <div style={{ flexBasis: '3em' }} >
        <Image className='potrait' src="/images/partner-17.png" alt="Partner 17" width={139} height={150} />
      </div>

      <div style={{ flexBasis: '3.5em' }}>
        <Image  src="/images/partner-18.png" alt="Partner 18" width={195} height={150} />
      </div>

      <div style={{ flexBasis: '3.5em' }}>
        <Image  src="/images/partner-19.png" alt="Partner 19" width={170} height={150} />
      </div>
            
      </div>
    );
  }
  const [productCarousels, setProductsCarousels] = useState([]);

  useEffect(() => {
    const fetchProductsCarousels = async () => {
      const newProduct = await Api.getNewProductsIndex(userToken, String(locale).toUpperCase())

      setProductsCarousels(newProduct);
    };
    fetchProductsCarousels();
  }, []);  

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData.organizationSchema) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData.websiteSchema) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData.imageObjectSchema) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData.webPageSchema) }}
      />

      <div className="main-content slide-disable sr t-05-ease">
        <div className={'thumbs v3 pt-1 pb-1 ' + toggleBackground()}>
          <div className="wrap-2 centralized">
            <div className="thumbs flex-box gap-1">
              <div>
                <a
                  style={{ color: '#4c818c' }}
                  href={basepath(`${locale === 'en' ? '' : `/${locale}`}/${pharmacy}`)}
                  target="_self"
                  className="t-05-ease"
                >
                  <small>{t('online')}</small>
                  <div>{t('pharmacy')}</div>
                  <Image 
                    placeholder='blur'
                    src={basepath('/images/box-1.jpg')} 
                    blurDataURL={basepath('/images/box-1.jpg')}
                    alt={"pharmacy"}
                    loader={loaderProp}
                    layout="fill" 
                    priority={true}	
                  />
                </a>
              </div>
              <div>
                <a
                  style={{ color: '#06666f' }}
                  href={basepath(`${locale === 'en' ? '' : `/${locale}`}/health-services`)}
                  target="_self"
                  className="t-05-ease"
                >
                  <small>{t('checkup')}</small>
                  <div>{t('healthScreeningPackages')}</div>
                  <Image 
                    placeholder='blur'
                    src={basepath('/images/box-3.jpg')} 
                    blurDataURL={basepath('/images/box-3.jpg')}
                    alt={"pharmacy"}
                    loader={loaderProp}
                    layout="fill" 
                    priority={true}	
                  />
                </a>
              </div>
              <div>
                <a
                  style={{ color: '#33706b' }}
                  href={basepath(`${locale === 'en' ? '' : `/${locale}`}/health-services`)}
                  target="_self"
                  className="t-05-ease"
                >
                  <small>{t('online')}</small>
                  <div>{t('homeHealthScreening')}</div>
                  <Image 
                    placeholder='blur'
                    src={basepath('/images/box-8.jpg')} 
                    blurDataURL={basepath('/images/box-8.jpg')}
                    alt={"pharmacy"}
                    loader={loaderProp}
                    layout="fill" 
                    priority={true}	
                  />
                </a>
              </div>
              <div>
                <a
                  style={{ color: '#a38e2c' }}
                  href={basepath(`${locale === 'en' ? '' : `/${locale}`}/health-services`)}
                  target="_self"
                  className="t-05-ease"
                >
                  <small>COVID-19</small>
                  <div>{t('kidsVaccination')}</div>
                  <Image 
                    placeholder='blur'
                    src={basepath('/images/box-6.jpg')} 
                    blurDataURL={basepath('/images/box-6.jpg')}
                    alt={"pharmacy"}
                    loader={loaderProp}
                    layout="fill"
                    priority={true}	
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={'pt-1 pb-1 o-h ' + toggleBackground()}>
          <div className="pharmacy-main-banners-wrapper centralized">
            <div className="pharmacy-main-banners flex-box gap-1 banners-slider google-ad-main">
              <GoogleAd
                adUnitPath="/21826856561/B2c-mp-main-banner-1"
                size={[1000, 420]}
                divId="div-gpt-ad-1722397442672-0"
              />
              <div className="flex-box f-g1-s1 fd-c gap-1 no-scroller">
                <GoogleAd
                  adUnitPath="/21826856561/b2c-mp-sub-banner-1"
                  size={[420, 190]}
                  divId="div-gpt-ad-1722395327856-0"
                />
                <GoogleAd
                  adUnitPath="/21826856561/B2c-mp-sub-banner-2"
                  size={[420, 190]}
                  divId="div-gpt-ad-1722395479203-0"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className={'section ' + toggleBackground()}>
          <ProductSlider
            title={t('onlinePharmacyIndex')}
            description={t('onlinePharmacyDesc')}
            tag={t('newIndex')}
            is_service={false}
            items={productCarousels}
            translatedCategories={translatedCategories}
            pharmacy={pharmacy}
            locale={locale}
            location={'homeIndex'}
          />
        </div>

        {/* {!productsCarousels?.length > 0 ? (
          <>
            <div className={'section ' + toggleBackground()}>
              <ProductSlider
                title={t('onlinePharmacyIndex')}
                description={t('onlinePharmacyDesc')}
                tag={t('newIndex')}
                is_service={false}
                items={newProduct}
                translatedCategories={translatedCategories}
                pharmacy={pharmacy}
                locale={locale}
                location={'homeIndex'}
              />
            </div>
          </>
        ) : (
          productsCarousels.map((x) => {
            return (
              <div className={'section ' + toggleBackground()}>
                <ProductSlider
                  title={x.title}
                  description={x.description[locale]}
                  items={x.content.items}
                  translatedCategories={translatedCategories}
                  pharmacy={pharmacy}
                  locale={locale}
                  collection={x}
                  location={'homeIndex'}
                />
              </div>
            )
          })
        )} */}

        {/* <div className={'section ' + toggleBackground()}>
          <ProductSlider
            is_service={true}
            title={t('topPackeges')}
            description={t('checkOutOurMostPopularItems')}
            items={newPackageProduct}
            locale={locale}
            location={'homeIndex'}
          ></ProductSlider>
        </div> */}

        <div className={'section ' + toggleBackground()}>
          <div className="wrap-2 centralized">
            <div className="banners v3 flex-box col3 fullwidth no-scroller-1024 gap-1">
              <a
                href={basepath(
                  `${locale === 'en' ? '' : `/${locale}`}/${pharmacy}/vitamin-suplemen`
                )}
                className="pos-rel f-g1-s1"
              >
                <div className="pos-abs flex-box fd-c fullheight fullwidth">
                  <div>
                    <div className="category">
                      <span>{t('personalCare')}</span>
                    </div>
                    <h2 style={{ color: '#de71a3' }}>{t('pamperYourselfWithDailyEssentails')}</h2>
                  </div>
                  <div>
                    <div className="button-white" style={{ color: '#b87489' }}>
                      {t('shopNow')}
                    </div>
                  </div>
                </div>

                <Image 
                    placeholder='blur'
                    src={basepath('/images/banner-1.jpg')} 
                    blurDataURL={basepath('/images/banner-1.jpg')}
                    alt={"pharmacy"}
                    loader={loaderProp}
                    layout="fill"
                    priority={false}	
                  />
              </a>
              <a
                href={basepath(
                  `${locale === 'en' ? '' : `/${locale}`}/${pharmacy}/vitamin-suplemen`
                )}
                className="pos-rel f-g1-s1"
              >
                <div className="pos-abs flex-box fd-c fullheight fullwidth">
                  <div>
                    <div className="category">
                      <span>{t('overTheCounter')}</span>
                    </div>
                    <h2 style={{ color: '#767676' }}>{t('fromStuffyNoseToMinorInfections')}</h2>
                  </div>
                  <div>
                    <div className="button-white" style={{ color: '#767676' }}>
                      {t('shopNow')}
                    </div>
                  </div>
                </div>

                <Image 
                    placeholder='blur'
                    src={basepath('/images/banner-2.jpg')} 
                    blurDataURL={basepath('/images/banner-2.jpg')}
                    alt={"pharmacy"}
                    loader={loaderProp}
                    layout="fill" 
                    priority={false}	
                  />
              </a>
              <a
                href={basepath(
                  `${locale === 'en' ? '' : `/${locale}`}/${pharmacy}/vitamin-suplemen`
                )}
                className="pos-rel f-g1-s1"
              >
                <div className="pos-abs flex-box fd-c fullheight fullwidth">
                  <div>
                    <div className="category">
                      <span>{t('overTheCounter')}</span>
                    </div>
                    <h2 style={{ color: '#fff' }}>{t('getTheReliefYouNeed')}</h2>
                  </div>
                  <div>
                    <div className="button-white" style={{ color: '#767676' }}>
                      {t('shopNow')}
                    </div>
                  </div>
                </div>

                <Image 
                    placeholder='blur'
                    src={basepath('/images/banner-3.jpg')} 
                    blurDataURL={basepath('/images/banner-3.jpg')}
                    alt={"pharmacy"}
                    loader={loaderProp}
                    layout="fill" 
                    priority={false}	
                  />
              </a>
            </div>
          </div>
        </div>
{/* 
        {
          personalizedProducts?.length>5&&
          <div className={"section "+ toggleBackground()}>
            <ProductSlider
                title={t('justForYou')}
                description={t('personalizedSelection')}
                tag={t('newIndex')}
                is_service={false}
                items={personalizedProducts}
                translatedCategories={translatedCategories}
                pharmacy={pharmacy}
                locale={locale}
              >
            </ProductSlider>
          </div>
        } */}

        <div className={'section ' + toggleBackground()}>
          <div className="wrap-1 centralized pad ta-c">
            <h2>{t('partnersTitle')}</h2>
            <p className="fs-1 centralized" style={{ maxWidth: '50em' }}>
              {t('partnersDesc')}
            </p>
            {PartnerLogos()}
          </div>
        </div>
        <div className={'section ' + toggleBackground()}>
          <MediaCarousel></MediaCarousel>
        </div>

        <div className={toggleBackground()}>
          <RatingReview isMobile={isMobile} t={t}></RatingReview>
        </div>
      </div>
      <GoogleAd
        adUnitPath="/21826856561/b2c-ep-square-popup"
        size={[500, 500]}
        divId="div-gpt-ad-1726196338518-0"
        popup="true"
      />
    </>
  )
}

export const getServerSideProps = async (context) => {
  const pharmacySlugs = {
    id: 'apotek',
    en: 'pharmacy',
    sg: 'pharmacy'
  }
  const { req, res } = context
  res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')
  let { i18nLocale, locale } = localeMapper(context.locale)

  let userToken = await anonymousAuth(req, res)

  // let newProduct = await Api.getNewProducts(userToken, String(locale).toUpperCase())
  // let newPackageProduct = await Api.getNewPackages(userToken, locale)
  // let productsCarousels =
  //   (await Api.getPackageCollections({
  //     token: userToken,
  //     locale,
  //     type: 'products',
  //     carousel: true
  //   })) || null

  // let personalizedProducts = await Api.getPersonalized(userToken, String(locale).toUpperCase())
  // let productCategories = await Api.getProductCategories()

  // const [newProduct, newPackageProduct, productsCarousels, personalizedProducts, productCategories] = await Promise.all([
    const [productCategories] = await Promise.all([
    // Api.getNewProductsIndex(userToken, String(locale).toUpperCase()),
    // Api.getNewPackages(userToken, locale),
    // Api.getPackageCollections({ token: userToken, locale, type: 'products', carousel: true }),
    // Api.getPersonalized(userToken, String(locale).toUpperCase()),
    Api.getProductCategories()
  ])


  let pageHeader = {
    id: {
      title: 'Apotek Online Untuk Obat, Suplemen & Alat Kesehatan - DoctorOnCall ID',
      description:
        'DoctorOnCall adalah apotek online di Indonesia terlengkap dan terpercaya yang menjual obat generik, obat OTC, produk kesehatan, kecantikan, dan perawatan tubuh.'
    },
    en: {
      title: 'Online Pharmacy For Medicines, Supplements & Healthcare Devices - DoctorOnCall',
      description:
        'DoctorOnCall is your most trusted and reliable online pharmacy, offering a wide range of generic medicines, over-the-counter drugs, health products, beauty items, and personal care treatments.'
    }
  }
  let currentFullUrl = `${context.req.headers.host}${context.req.url}`

  let translatedCategories = {}

  productCategories.map((category) => {
    translatedCategories[category.language.en.name] =
      category.language[locale]?.url_slug || category.language['en'].url_slug
  })
  //set prop to open sign up overlay is register=true is passed in url
  let openSignUpOverlay = false
  if (context.req.query?.register == 'true') {
    openSignUpOverlay = true
  }

  const structuredData = schemaGeneratorMain({
    locale: locale
  })

  return {
    props: {
      pageHeader: {
        title: pageHeader[i18nLocale]?.title || pageHeader['en'].title,
        description: pageHeader[i18nLocale]?.description || pageHeader['en'].description
      },
      // newProduct: newProduct,
      // newPackageProduct: newPackageProduct.packages,
      currentFullUrl,
      structuredData,
      locale: locale,
      pharmacy: pharmacySlugs[locale],
      // productsCarousels,
      // personalizedProducts,
      userToken,
      translatedCategories,
      openSignUpOverlay,
      ...(await serverSideTranslations(i18nLocale, ['auth', 'header', 'footer', 'index', 'common']))
    }
  }
}
